import { FC } from 'react';

import { hasRichTextContent } from '@lib/slice-machine/richText';
import { SxStyles } from '@lib/theme/sxTheme';
import { Paper, Stack } from '@mui/material';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';

import { BannerCardVariation } from '../types';
import { DEFAULT_SPACING_BETWEEN_ELEMENTS } from '../../constants';
import { SliceLayout } from '@ui/slice-machine/SliceLayout';

interface Props {
  slice: BannerCardVariation;
}

export const BannerCard: FC<Props> = ({ slice }) => {
  const {
    title,
    description,
    backgroundColor,
    paddingTop,
    paddingBottom,
    width,
    ctaTextLink,
    ctaBackgroundColor,
    ctaTextColor,
  } = slice.primary;
  const styles = getStyles();
  return (
    <SliceLayout
      backgroundColor={backgroundColor}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      width={width}
    >
      <Stack spacing={DEFAULT_SPACING_BETWEEN_ELEMENTS}>
        {hasRichTextContent(title) && (
          <CustomRichText
            containerSx={styles.getValue('title')}
            render={title}
          />
        )}
        <Paper sx={styles.getValue('card')} elevation={6}>
          <Stack spacing={DEFAULT_SPACING_BETWEEN_ELEMENTS}>
            <Stack spacing={2}>
              {slice.items.map((row, index) => {
                return (
                  hasRichTextContent(row.rowText) && (
                    <CustomRichText
                      key={`card-row-${index}`}
                      render={row.rowText}
                    />
                  )
                );
              })}
              {hasRichTextContent(description) && (
                <CustomRichText render={description} />
              )}
            </Stack>
            {hasRichTextContent(ctaTextLink) && (
              <CustomRichText
                render={ctaTextLink}
                linkBtnProps={{
                  linkAsButton: true,
                  color: ctaBackgroundColor ?? undefined,
                  textProps: {
                    color: ctaTextColor ?? undefined,
                    fontSize: 18,
                  },
                }}
              />
            )}
          </Stack>
        </Paper>
      </Stack>
    </SliceLayout>
  );
};

const getStyles = () => {
  return new SxStyles({
    title: {
      textAlign: 'center',
    },
    card: {
      px: 6,
      py: 4,
      borderRadius: 2,
    },
  });
};
