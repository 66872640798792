import { hasRichTextContent } from '@lib/slice-machine/richText';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, Divider, Paper, Stack } from '@mui/material';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SHARED_PALETTE } from '@lib/theme/constants';
import { Theme } from '@mui/system';
import { BannerCardWithImagesVariation } from '../types';
import { DEFAULT_SPACING_BETWEEN_ELEMENTS } from '../../constants';

interface Props {
  slice: BannerCardWithImagesVariation;
}

export const BannerCardContent: React.FC<Props> = ({ slice }) => {
  const {
    leftCardTitle,
    leftCardDescription,
    rightCardTitle,
    rightCardDescription,
    ctaTextLink,
    ctaBackgroundColor,
    ctaTextColor,
  } = slice.primary;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = getStyles(isMobile, theme);
  return (
    <Paper
      sx={styles.merge('card', isMobile && 'mobileCardWrapper')}
      elevation={10}
    >
      {!isMobile && <Box sx={styles.getValue('backgroundPale')} />}
      <Paper sx={styles.merge('leftCard', 'text')} elevation={2}>
        {hasRichTextContent(leftCardTitle) && (
          <CustomRichText render={leftCardTitle} />
        )}
        <Divider sx={styles.getValue('leftCardDivider')} variant="middle" />
        {hasRichTextContent(leftCardDescription) && (
          <CustomRichText render={leftCardDescription} />
        )}
      </Paper>
      <Box sx={styles.merge('rightCard', 'text')}>
        <Stack spacing={DEFAULT_SPACING_BETWEEN_ELEMENTS}>
          <Box>
            {hasRichTextContent(rightCardTitle) && (
              <CustomRichText render={rightCardTitle} />
            )}
            <Divider sx={styles.getValue('divider')} variant="middle" />
            {hasRichTextContent(rightCardDescription) && (
              <CustomRichText render={rightCardDescription} />
            )}
          </Box>
          {hasRichTextContent(ctaTextLink) && (
            <CustomRichText
              render={ctaTextLink}
              linkBtnProps={{
                linkAsButton: true,
                color: ctaBackgroundColor ?? undefined,
                textProps: {
                  color: ctaTextColor ?? undefined,
                  fontSize: 18,
                },
              }}
            />
          )}
        </Stack>
      </Box>
    </Paper>
  );
};

const getStyles = (isMobile: boolean, theme: Theme) => {
  return new SxStyles({
    mobileCardWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      pt: 8,
      justifyContent: 'center',
      alignItems: 'center',
    },
    card: {
      position: 'relative',
      width: '100%',
      height: isMobile ? 'auto' : 400,
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 2,
      zIndex: 1,
    },
    leftCardDivider: {
      borderColor: theme.palette.secondary.main,
      borderWidth: 2,
      my: 2,
    },
    divider: {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
      my: 2,
    },
    leftCard: {
      ...(!isMobile && {
        position: 'absolute',
        top: '20%',
        left: '10%',
        width: '40%',
        maxHeight: '60%',
        minHeight: '50%',
      }),
      height: 'auto',
      borderRadius: 4,
      backgroundColor: theme.palette.primary.dark,
      border: `5px solid ${theme.palette.secondary.main}`,
      p: theme.spacing(2, 1),
    },
    rightCard: {
      py: DEFAULT_SPACING_BETWEEN_ELEMENTS,

      ...(!isMobile && {
        position: 'absolute',
        top: '20%',
        right: '10%',
        width: '40%',
        height: 'auto',
        minHeight: '50%',
        maxHeight: '60%',
        pl: 1,
      }),
    },
    text: {
      textAlign: 'center',
      maxWidth: '80%',
      textWrap: 'wrap',
    },
    backgroundPale: {
      backgroundColor: SHARED_PALETTE.lightGrey,
      width: '40%',
      height: '100%',
      borderRadius: 2,
    },
  });
};
