import { FC } from 'react';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, Grid, Stack } from '@mui/material';
import { BannerWithColumnsVariation } from '../types';
import { DynamicImage } from '@ui/gallery/DynamicImage';
import { hasRichTextContent } from '@lib/slice-machine/richText';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';
import {
  BackgroundColor,
  ItemPosition,
  TextColor,
} from '@lib/slice-machine/types';
import { DEFAULT_SPACING_BETWEEN_ELEMENTS } from '../../constants';
import { SliceLayout } from '@ui/slice-machine/SliceLayout';

interface Props {
  slice: BannerWithColumnsVariation;
}

export const BannerWithColumns: FC<Props> = ({ slice }) => {
  const {
    backgroundColor,
    title,
    subtitle,
    bottomText,
    position,
    paddingTop,
    paddingBottom,
    width,
    showCardBorder,
    cardBorderColor,
    cardBackgroundColor,
    showCardNumber,
    cardNumberBackgroundColor,
    cardNumberColor,
  } = slice.primary;
  const dynamicStyles = styles({
    position,
    showCardBorder,
    cardBorderColor,
    cardBackgroundColor,
    cardNumberBackgroundColor,
    cardNumberColor,
  });

  return (
    <SliceLayout
      backgroundColor={backgroundColor}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      width={width}
    >
      <Stack spacing={DEFAULT_SPACING_BETWEEN_ELEMENTS} alignItems="stretch">
        {hasRichTextContent(title) && (
          <CustomRichText
            render={title}
            containerSx={dynamicStyles.getValue('title')}
          />
        )}
        {hasRichTextContent(subtitle) && (
          <CustomRichText
            render={subtitle}
            containerSx={dynamicStyles.getValue('subtitle')}
          />
        )}
        <Box>
          <Grid container spacing={DEFAULT_SPACING_BETWEEN_ELEMENTS}>
            {slice.items?.map((item, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{
                    textAlign: item.position ?? 'center',
                  }}
                >
                  <Box sx={dynamicStyles.getValue('card')}>
                    {showCardNumber && (
                      <Box
                        sx={dynamicStyles.getValue('number')}
                        component="span"
                      >
                        {index + 1}
                      </Box>
                    )}
                    {item.image?.url && (
                      <DynamicImage
                        containerProps={{
                          sx: dynamicStyles.getValue('image'),
                        }}
                        src={item.image.url}
                        alt={item.image?.alt ?? ''}
                        width={item.image.dimensions.width as number}
                        height={item.image.dimensions.height as number}
                      />
                    )}
                    {hasRichTextContent(item.text) && (
                      <CustomRichText
                        render={item.text}
                        containerSx={dynamicStyles.getValue('text')}
                      />
                    )}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        {hasRichTextContent(bottomText) && (
          <CustomRichText
            render={bottomText}
            containerSx={dynamicStyles.getValue('bottomText')}
          />
        )}
      </Stack>
    </SliceLayout>
  );
};

interface StyleProps {
  position: ItemPosition | null;
  showCardBorder: boolean | null;
  cardBackgroundColor: BackgroundColor | null;
  cardBorderColor: TextColor | null;
  cardNumberBackgroundColor: BackgroundColor | null;
  cardNumberColor: TextColor | null;
}

const styles = ({
  position,
  showCardBorder,
  cardBorderColor,
  cardBackgroundColor,
  cardNumberBackgroundColor,
  cardNumberColor,
}: StyleProps) => {
  return new SxStyles({
    title: {
      textAlign: position ?? 'center',
    },
    subtitle: {
      textAlign: position ?? 'center',
    },
    bottomText: {
      textAlign: position ?? 'center',
      fontSize: '0.875rem',
    },
    card: {
      height: '100%',
      backgroundColor: cardBackgroundColor,
      ...(showCardBorder && {
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: cardBorderColor,
        borderRadius: 3,
        p: 3,
      }),
    },
    number: {
      display: 'inline-block',
      width: 40,
      height: 40,
      lineHeight: 1.65,
      mt: -1,
      mb: 2,
      backgroundColor: cardNumberBackgroundColor,
      color: cardNumberColor,
      borderRadius: '50%',
      fontWeight: 'fontWeightBold',
      fontSize: 'h5.fontSize',
      textAlign: 'center',
    },
    text: {
      fontSize: '1.125rem',
    },
    image: {
      img: {
        borderRadius: 5,
        maxWidth: `100% !important`,
        height: 'auto',
      },
    },
  });
};
